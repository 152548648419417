import * as React from "react";
import Container from "../container/container";
import Button from "../button";
import Link from "../link";

import * as styledComponents from "./footer.module.scss";

const Footer = () => {
  return (
    <footer>
      <div className={styledComponents.blue}>
        <Container>
          <div className={styledComponents.flex}>
            <h2>N'HESITEZ PAS A NOUS APPELER</h2>
            <div className={styledComponents.center}>
              <Link to="tel:15146535548">
                <Button
                  givenBackground="var(--bg3)"
                  givenColor="var(--bg1)"
                  givenFontFamily="KFO"
                  givenPadding="20px 50px"
                >
                  514 653-5548
                </Button>
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className={styledComponents.black}>
          <div>
            <p>© 2021 Heliot Vilchis </p>
          </div>
          <div>{/*logo*/}</div>
          <div>{/*SOCIAL*/}</div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
