import * as React from "react";
import styled from "styled-components";

const Button = ({
  children,
  givenColor,
  givenBackground,
  givenFontFamily,
  givenPadding,
}) => {
  const StyledButton = styled.button`
    border: none;
    letter-spacing: 1px;
    margin-right: 20px;
  `;

  return (
    <StyledButton
      style={{
        background: givenBackground,
        color: givenColor,
        fontFamily: givenFontFamily,
        padding: givenPadding,
      }}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
