import * as React from "react";
import Link from "../link";
import Button from "../button";
import Container from "../container/container";

import Logo from "../../images/icon.png";

import * as styledComponents from "./nav.module.scss";

const Nav = () => {
  return (
    <Container>
      <nav>
        <Link to="/">
          <img src={Logo} alt="Aide-Reno" className={styledComponents.logo} />
        </Link>

        <ul>
          <li>
            <Link to="/nos-services">Nos services</Link>
          </li>
          <li>
            <Link to="tel:15146535548">
              <Button
                givenBackground="var(--bg3)"
                givenColor="var(--bg1)"
                fivenFontFamily="KFO"
                givenPadding="10px 25px"
              >
                514-653-5548
              </Button>
            </Link>
          </li>
          <li className={styledComponents.language}>
            <Link to="/">English</Link>
          </li>
        </ul>
      </nav>
    </Container>
  );
};

export default Nav;
